import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import MyProvider from "./Components/MyProvider";
import MyContext from "./Components/Context";
import { ScaleLoader } from "react-spinners";
import UnProtectedPayment from "./Components/UnProtectedPayment/UnProtectedPayment";

// Lazy loaded components
const FlightSearch = lazy(() =>
  import("./Components/Flights/FlightSearch/FlightSearch")
);
const FlightBooking = lazy(() =>
  import("./Components/Flights/FlightBooking/FlightBooking")
);
const LoginPage = lazy(() =>
  import("./Components/Authentication/LoginPage/LoginPage")
);
const SignUpPage = lazy(() =>
  import("./Components/Authentication/SignUpPage/SignUpPage")
);
const MyTrips = lazy(() => import("./Components/Trips/MyTrips/MyTrips"));
const TripDetails = lazy(() =>
  import("./Components/Trips/TripDetails/TripDetails")
);
const AdminPage = lazy(() => import("./Components/Admin/AdminPage/AdminPage"));
const AdminDetails = lazy(() =>
  import("./Components/Admin/AdminDetails/AdminDetails")
);
const AdminRoute = lazy(() => import("./Components/ProtectedRoute/AdminRoute"));
const ProtectedRoute = lazy(() =>
  import("./Components/ProtectedRoute/ProtectedRoute")
);
const FlightSetting = lazy(() =>
  import("./Components/Admin/FlightSettings/FlightSetting")
);
const HotelSetting = lazy(() =>
  import("./Components/Admin/HotelSettings/HotelSetting")
);
const Users = lazy(() => import("./Components/Admin/Users/Users"));
const ChangePassword = lazy(() =>
  import("./Components/User/ChangePassword/ChangePassword")
);
const Home = lazy(() => import("./Components/Home/Home"));
const Privacy = lazy(() => import("./Components/Home/Privacy"));
const TermsAndConditions = lazy(() =>
  import("./Components/Home/TermsAndConditions")
);
const CancellationPolicy = lazy(() =>
  import("./Components/Home/CancellationPolicy")
);
const Profile = lazy(() => import("./Components/User/Profile/Profile"));
const CommissionSetting = lazy(() =>
  import("./Components/Admin/CommissionSetting/CommissionSetting")
);
const Role = lazy(() => import("./Components/User/Role/Role"));
const Wallet = lazy(() => import("./Components/Wallet/Wallet"));
const UserProfile = lazy(() =>
  import("./Components/Admin/UserProfile/UserProfile")
);
const UserTrips = lazy(() => import("./Components/Admin/UserTrips/UserTrips"));
const Report = lazy(() => import("./Components/Admin/Report/Report"));
const AllRequests = lazy(() => import("./Components/User/Role/AllRequests"));
const AllBookings = lazy(() =>
  import("./Components/Admin/AdminPage/AllBookings")
);
const Downloads = lazy(() => import("./Components/Downloads/Downloads"));
const FlightSeats = lazy(() => import("./Components/FlightSeats/FlightSeats"));

const Loader = () => (
  <div className="flex items-center justify-center h-[100vh]">
    <ScaleLoader />
  </div>
);

function App() {
  return (
    <MyProvider>
      <Router>
        <MyContext.Consumer>
          {({ actions }) => (
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/cancellation" element={<CancellationPolicy />} />
                <Route path="/downloads" element={<Downloads />} />
                <Route path="/:threadId/:flightId" element={<FlightSeats />} />
                <Route
                  path="/checkout/:userId"
                  element={<UnProtectedPayment />}
                />
                <Route
                  path="/home"
                  element={<ProtectedRoute element={<FlightSearch />} />}
                />
                <Route
                  path="/home/flights"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="flights" />} />
                  }
                />
                <Route
                  path="/home/hotels"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="hotels" />} />
                  }
                />
                <Route
                  path="/home/cabs"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="cabs" />} />
                  }
                />
                <Route
                  path="/home/bus"
                  element={
                    <ProtectedRoute element={<FlightSearch tab="bus" />} />
                  }
                />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route
                  path="/trips"
                  element={<ProtectedRoute element={<MyTrips />} />}
                />
                <Route
                  path="/trips/:id"
                  element={<ProtectedRoute element={<TripDetails />} />}
                />
                <Route
                  path="/changepassword"
                  element={<ProtectedRoute element={<ChangePassword />} />}
                />
                <Route
                  path="/profile"
                  element={<ProtectedRoute element={<Profile />} />}
                />
                <Route
                  path="/roles"
                  element={<ProtectedRoute element={<Role />} />}
                />
                <Route
                  path="/allrequests"
                  element={<ProtectedRoute element={<AllRequests />} />}
                />
                <Route
                  path="/wallet"
                  element={<ProtectedRoute element={<Wallet open={false} />} />}
                />
                <Route
                  path="/admin"
                  element={<AdminRoute element={<AdminPage />} />}
                />
                <Route
                  path="/admin/:id"
                  element={<AdminRoute element={<AdminDetails />} />}
                />
                <Route
                  path="/flightsetting"
                  element={<AdminRoute element={<FlightSetting />} />}
                />
                <Route
                  path="/hotelsetting"
                  element={<AdminRoute element={<HotelSetting />} />}
                />
                <Route
                  path="/commissionsetting"
                  element={<AdminRoute element={<CommissionSetting />} />}
                />
                <Route
                  path="/report"
                  element={<AdminRoute element={<Report />} />}
                />
                <Route
                  path="/users"
                  element={<AdminRoute element={<Users />} />}
                />
                <Route
                  path="/allbookings"
                  element={<AdminRoute element={<AllBookings />} />}
                />
                <Route
                  path="/users/:id"
                  element={<AdminRoute element={<UserProfile />} />}
                />
                <Route
                  path="/users/:userId/trips/:tripId"
                  element={<AdminRoute element={<UserTrips />} />}
                />
              </Routes>
            </Suspense>
          )}
        </MyContext.Consumer>
      </Router>
    </MyProvider>
  );
}

export default App;
